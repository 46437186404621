import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DestroyBase } from '@core/base/destroy.class';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-split-stem-amount',
  templateUrl: './split-stem-amount.component.html',
  styleUrls: ['./split-stem-amount.component.scss'],
})
export class SplitStemAmountComponent extends DestroyBase implements OnInit {
  @Input() public fields: string[] = ['original_stems', 'stems'];
  @Input() public originalStems: number;
  @Output() public splitStemChange: EventEmitter<any> = new EventEmitter();

  public slider: number;
  public leftField: UntypedFormControl;
  public rightField: UntypedFormControl;

  public ngOnInit(): void {
    this.initValues();
  }

  public sliderChange(value: number): void {
    this.leftField.setValue(value);
    this.rightField.setValue(this.calc(value));

    this.updateValueInParentComponent();
  }

  public leftFieldChange(): void {
    this.rightField.setValue(this.calc(this.leftField.value));
    this.slider = this.leftField.value;

    this.updateValueInParentComponent();
  }

  public rightFieldChange(): void {
    this.leftField.setValue(this.calc(this.rightField.value));
    this.slider = this.calc(this.rightField.value);

    this.updateValueInParentComponent();
  }

  private initValues(): void {
    const originalStems = +this.originalStems;
    const firstHalf = Math.round(originalStems / 2);
    const secondHalf = originalStems - firstHalf;

    this.slider = firstHalf;
    this.leftField = new UntypedFormControl(firstHalf, [Validators.min(0), Validators.max(originalStems)]);
    this.rightField = new UntypedFormControl(secondHalf, [Validators.min(0), Validators.max(originalStems)]);

    this.updateValueInParentComponent();
  }

  private calc(nr1: number): number {
    const value = Number(this.originalStems) - Number(nr1);

    return value > 0 ? Math.min(value, +this.originalStems) : 0;
  }

  private updateValueInParentComponent(): void {
    const data = {
      [this.fields[0]]: this.leftField.value,
      [this.fields[1]]: this.rightField.value,
    };

    this.splitStemChange.emit(data);
  }
}
