<div class="mt-3 text-center">
  <h6 class="mb-2 semi-bold">{{ 'dxp.auction-sales.split_stems' | translate }}</h6>

  <p-slider [(ngModel)]="slider" [min]="0" [max]="originalStems" (onChange)="sliderChange($event.value)"> </p-slider>

  <div class="d-flex justify-content-between">
    <cap-input [formControl]="leftField" type="number" (onInput)="leftFieldChange()"> </cap-input>

    <cap-input [formControl]="rightField" type="number" (onInput)="rightFieldChange()"> </cap-input>
  </div>
</div>
