import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuctionOrderService, ManualStems, SplitDay, SplitSalesChannel } from '@core/api/auction-order.service';
import { Store } from '@ngxs/store';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DestroyBase } from '@core/base/destroy.class';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SalesChannelService } from '@core/api/sales-channel.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import moment from 'moment/moment';
import { DatesFormats } from '@core/configs/date-config';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { AuctionOrder } from '@core/models/auction-order.model';

export enum Action {
  splitDays = 'split_date',
  splitChannels = 'split_channels',
  adjustStems = 'split_stems',
}

@Component({
  selector: 'app-auction-order-actions',
  templateUrl: './auction-order-actions.component.html',
  styleUrls: ['./auction-order-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuctionOrderActionsComponent extends DestroyBase implements OnInit {
  public form: UntypedFormGroup;
  public salesChannels$: Observable<MapItem[]>;
  public currentAction: string;
  public auctionDetails: AuctionOrder;

  constructor(
    private auctionOrderService: AuctionOrderService,
    private store: Store,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private config: DynamicDialogConfig,
    private salesChannelService: SalesChannelService,
    private dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    const actionKey = this.config?.data?.action?.key;

    this.auctionDetails = this.config?.data?.item;
    this.salesChannels$ = this.salesChannelService.getItemsAsOptionsWithAuctionStrategyFilter().pipe(
      map((items) => {
        return items?.filter((item) => {
          return item.value !== this.auctionDetails.sales_channel_id;
        });
      }),
    );
    this.currentAction = actionKey;

    this.initForm();
  }

  public submit(): void {
    if (this.form.invalid) {
      this.form.markAsTouched();

      return;
    }

    let payload = this.form?.value;

    if (this.form.get('to_date')) {
      payload = { ...payload, to_date: moment(this.form.value.to_date).format(DatesFormats.dayFormat) };
    }

    switch (this.currentAction) {
      case Action.splitDays:
        this.auctionOrderService.splitDay(payload as SplitDay).subscribe(() => {
          this.updateAuctionDetails();
        });
        break;

      case Action.splitChannels:
        this.auctionOrderService.splitSalesChannel(payload as SplitSalesChannel).subscribe(() => {
          this.updateAuctionDetails();
        });
        break;

      case Action.adjustStems:
        this.auctionOrderService.createManualStems(payload as ManualStems).subscribe(() => {
          this.updateAuctionDetails();
        });
        break;
      default:
        break;
    }

    this.cancel();
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  private updateAuctionDetails(): void {
    this.notificationService.success(
      this.translateService.instant('toast.success.title'),
      this.translateService.instant(`dxp.auction-sales.button.${this.currentAction}`),
    );
    this.store.dispatch(new FetchActionsExecution(BuilderActionType.refresh));
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({});

    const originalStems = {
      name: 'original_stems',
      value: +this.auctionDetails?.stems,
      validations: [Validators.min(0), Validators.max(+this.auctionDetails?.stems)],
    };
    const stems = {
      name: 'stems',
      validations: [Validators.required, Validators.min(1), Validators.max(+this.auctionDetails?.stems)],
    };
    const fields: {
      value?: string | number | Date;
      name: string;
      validations: ValidatorFn[];
    }[] = [{ name: 'auction_order_id', value: this.auctionDetails?.id, validations: [Validators.required] }];

    switch (this.currentAction) {
      case Action.splitDays:
        fields.push({ name: 'to_date', validations: [Validators.required] }, stems, originalStems);
        break;
      case Action.splitChannels:
        fields.push({ name: 'sales_channel_id', validations: [Validators.required] }, stems, originalStems);
        break;
      case Action.adjustStems:
        fields.push({ ...stems, validations: [Validators.required, Validators.min(1)] });
        break;
      default:
        break;
    }

    fields.forEach((field) => {
      this.form.addControl(field.name, new UntypedFormControl(field.value || null, field.validations));
    });

    this.form.patchValue({ ...this.auctionDetails, sales_channel_id: null });
  }
}
