import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { ConverterUtil } from '@core/utils/converter-util';
import { map } from 'rxjs/operators';
import { AuctionDashboardResponse, AuctionWeekItem } from '@core/models/auction-dashboard-response.model';
import { AuctionType } from './../enums/auction-type.enum';
import { AuctionItems } from '../../features/admin/auction-sales/components/display-stems/display-stems.component';

export interface DetailsBody {
  date: string;
  product_id?: string;
  grading_id?: string;
  sales_channel_id?: string;
  farm_id?: string;
  type: AuctionType;
}

export interface DashboardBody {
  product_id?: string;
  date_start: string;
  date_end: string;
}

export interface SplitDay {
  auction_order_id: string;
  to_date: string;
  stems: number;
}

export interface SplitSalesChannel {
  auction_order_id: string;
  sales_channel_id: string;
  stems: number;
}

export interface ManualStems {
  auction_order_id: string;
  stems: number;
}

export interface Recalculate {
  date_start: string;
  date_end: string;
  product_id: string;
  grading_id?: string;
  farm_id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuctionOrderService extends ApiService<AuctionDashboardResponse> {
  protected endpoint = 'auction-order';

  constructor(apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getItemsAsListOptions(options?: ListOptions): Observable<MapItem[]> {
    return this.list(options).pipe(
      map((response) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  public getDashboard(
    data: DashboardBody,
    options?: ListOptions,
  ): Observable<ApiSingleResult<AuctionDashboardResponse>> {
    let url = `/${this.endpoint}/dashboard`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }

  public getPopUpDetails(data: DetailsBody): Observable<AuctionItems[]> {
    return this.apiHttp.post<ApiSingleResult<AuctionItems[]>>(`/${this.endpoint}/popup`, data).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public popUpLock(data: AuctionWeekItem): Observable<AuctionWeekItem> {
    return this.apiHttp
      .patch<ApiSingleResult<AuctionWeekItem>>(`/${this.endpoint}/${data.auction_order_id}/lock`, data)
      .pipe(
        map((result) => {
          return result?.data;
        }),
      );
  }

  public splitDay(data: SplitDay): Observable<AuctionWeekItem> {
    return this.apiHttp.post<ApiSingleResult<AuctionWeekItem>>(`/${this.endpoint}/split-day`, data).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public splitSalesChannel(data: SplitSalesChannel): Observable<AuctionWeekItem> {
    return this.apiHttp.post<ApiSingleResult<AuctionWeekItem>>(`/${this.endpoint}/split-sales-channel`, data).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public createManualStems(data: ManualStems): Observable<AuctionWeekItem> {
    return this.apiHttp.post<ApiSingleResult<AuctionWeekItem>>(`/${this.endpoint}/manual-stems`, data).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public recalculate(data: Recalculate): Observable<AuctionWeekItem> {
    return this.apiHttp.post<ApiSingleResult<AuctionWeekItem>>(`/${this.endpoint}/recalculate`, data).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }
}
