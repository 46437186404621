<div class="row subtitle">
  <div class="col-6">
    <div class="pb-2">{{ auctionDetails?.grading?.product?.short_name }} - {{ auctionDetails?.grading.code }}</div>

    <div>{{ auctionDetails?.stems | number }} &#64; {{ auctionDetails?.salesChannel?.name }}</div>
  </div>

  <div class="col-6">
    <div class="pb-2">{{ auctionDetails?.batch?.characteristics }}</div>

    <div class="row">
      <div class="col-4 semi-bold pe-0">{{ 'dxp.auction-sales.packrate.label' | translate }}</div>

      <div class="col ps-0">{{ auctionDetails?.packRate }}</div>
    </div>
  </div>
</div>

@if (form) {
  <form [formGroup]="form">
    <div class="auction-order-body">
      @if (form.get('stems') && !form.get('original_stems')) {
        <div class="form-line">
          <div class="form-group">
            <div class="semi-bold">{{ 'dxp.auction-sales.original_stems' | translate }}</div>
            <div class="mt-3" data-test="edit-stems-auction-value">{{ auctionDetails?.stems | number }}</div>
          </div>
          <div class="form-group">
            <cap-input
              formControlName="stems"
              [label]="'dxp.auction-sales.stem_amount.label' | translate"
              [placeholder]="'dxp.auction-sales.stem_amount.label' | translate">
            </cap-input>
          </div>
        </div>
      }
      @if (form?.get('to_date')) {
        <div class="form-line">
          <div class="form-group">
            <div class="semi-bold">{{ 'dxp.auction-sales.original_date' | translate }}</div>
            <div class="mt-3">{{ auctionDetails?.shipment.shipmentDate | defaultDateFormatting: 'dd-MM-yyyy' }}</div>
          </div>
          <div class="form-group">
            <cap-calendar
              appendTo="body"
              dateFormat="dd-mm-yy"
              formControlName="to_date"
              [hideOnDateTimeSelect]="true"
              [label]="'dxp.auction-sales.split_date' | translate"
              [placeholder]="'dxp.auction-sales.split_date' | translate"
              [showWeek]="true">
            </cap-calendar>
          </div>
        </div>
      }
      @if (form.get('sales_channel_id')) {
        <div class="form-line">
          <div class="form-group">
            <div class="semi-bold">{{ 'dxp.auction-sales.original_channel' | translate }}</div>
            <div class="mt-3">{{ auctionDetails?.salesChannel?.name }}</div>
          </div>
          <div class="form-group">
            <cap-dropdown
              appendTo="body"
              formControlName="sales_channel_id"
              [filter]="true"
              [label]="'dxp.auction-sales.split_channel' | translate"
              [options]="salesChannels$ | async"
              [placeholder]="'dxp.auction-sales.split_channel' | translate"
              [style]="{ minWidth: '100%' }">
            </cap-dropdown>
          </div>
        </div>
      }
      @if (form.get('original_stems') && form.get('stems')) {
        <app-split-stem-amount [originalStems]="auctionDetails.stems" (splitStemChange)="form.patchValue($event)">
        </app-split-stem-amount>
      }
    </div>
    <div class="auction-order-footer">
      <cap-button
        data-test="edit-stems-cancel-button"
        styleClass="w-100 secondary"
        [label]="'dxp.auction-sales.button.discard' | translate"
        (onClick)="cancel()">
      </cap-button>
      <cap-button
        data-test="edit-stems-submit-button"
        styleClass="w-100 primary"
        [disabled]="form.invalid"
        [label]="'dxp.auction-sales.button.save' | translate"
        (onClick)="submit()">
      </cap-button>
    </div>
  </form>
}
